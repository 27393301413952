import { Flex } from '@stars-ecom/shared-atoms-ui'
import { FaqLayout } from '@stars-ecom/shared-organisms-ui'
import React from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const FaqPage = (props) => {
  const { pageContext, location } = props
  const { faq } = pageContext

  return (
    <Flex w="full" justify="center" style={{ backgroundColor: '#f6f3f1' }}>
      <Helmet
        bodyAttributes={{
          class: 'chakra-ui-light'
        }}>
        <meta charSet="utf-8" />
        <title>{`${faq?.title} - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}</title>
        <meta
          name="description"
          content={`Retrouvez en ligne sur ${siteFormatter(
            process.env.GATSBY_API_WEBSITE
          )}, l'ensemble des produits présentés lors de votre émission de télé achat. Commandez en ligne !`}
        />
      </Helmet>
      <FaqLayout faq={faq} selected={location?.hash} />
    </Flex>
  )
}

FaqPage.pageType = 'faq'

export default FaqPage
